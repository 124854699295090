<template>
  <div class="nav">
    <img :src="require('@/assets/nav-icon.svg')" class="nav-img" />
    <span @click="goBack" class="back">首页</span>
    <span class="set-margin"> / </span>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
});
const { info, id } = toRefs(props);
const goBack = ()=>{
  router.push(`/home`)
}
</script>
<style lang="scss" scoped>
.nav {
  width: 876px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  font-family: Sans Bold;
  color: #1A1A1A;
  padding: 12px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(170, 170, 170, 0.2);
  
  .back{
    cursor: pointer;
  }
  .nav-img{
    margin-right: 8px;
  }
  .set-margin{
    margin: 0 4px;
  }
  .title{
    color: #3A63F3;
  }
}
</style>
