<template>
  <div class="hot">
    <div class="top">
      <div class="title">
        <img :src="require('@/assets/nav-icon.svg')" class="nav-img" />
        热门排行
      </div>
      <span class="rank">TOP5</span>
    </div>
    <div v-for="(item,index) in hotList" :key="index" class="list"  @click="toDetail(item)">
      <div class="item-info-title">
        <span v-if="item.label" class="item-info-lable">{{ item.label }}</span>
        {{ item.articleTitle }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
/* api */
import { getHot } from '@/api/home'

const hotList = ref([])

const getHotList = () => {
  getHot().then(res => {
    hotList.value = res.data.data
  })
}
getHotList()

const toDetail = (item) => {
  // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}
</script>
<style lang="scss" scoped>
.hot {
  width: 292px;
  height: 437px;
  border-radius: 4px;
  background: #F8F8F8;
  font-family: Sans Bold;
  padding: 24px 16px 0;
  box-sizing: border-box;
  .top{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 24px;
    .title{
      color: #1A1A1A;
      .nav-img{
        margin-right: 8px;
      }
    }
    .rank{
      color: rgba(26, 26, 26, 0.2);
    }
  }

  .list{
    border-top:  1px solid rgba(170, 170, 170, 0.2);
    padding: 12px 0;
    box-sizing: border-box;
    cursor: pointer;
    .item-info-title{
      font-size: 14px;
      height: 48px;
      line-height: 24px;
      color: #1A1A1A;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; 

      .item-info-lable{
        padding: 2px 4px;
        box-sizing: border-box;
        background: #FF3737;
        border-radius: 4px;
        line-height: 18px;
        color: #FFFFFF;
        font-size: 12px;
        margin-right: 2px;
        display: inline-block;
      }
    }
  }
}
</style>
