<template>
  <div class="detail-box">
    <NavBar v-if="isPc" />
    <div class="detail" :class="{'detailwidth' : isPc}">
      <navBar v-if="isPc && detail"  :title="detail && detail.articleTitle" ></navBar>
      <div class="main" :class="{'mar-t' : !detail}">
        <div class="main-left" :class="{'pcwidth' : isPc}">
          <template v-if="detail">
            <div class="title">
              <!-- <span v-if="detail.label" class="lable">{{ detail.label }}</span> -->
              <div class="detail-title">{{  detail.articleTitle }}</div>
            </div>
            <div class="pub-info"><span>{{ detail.creator }}</span><span class="time">{{ detail.publishTimeStr }}</span></div>
            <div v-html="detail.articleContent" class="content"></div>
            <div class="key">
              <span v-for="(item,index) in keys" :key="index" class="keyword">{{ item }}</span>
            </div>
          </template>
          <div v-if="errorMsg" class="error-box">
            <ErrorCircleFilledIcon size="60px" style="color: orange"/>
            <div class="error-text">{{ errorMsg }}</div>
          </div>
        </div>
        <!-- <div class="main-right" > -->
          <hot v-if="isPc"></hot>
        <!-- </div> -->
      </div>
    </div>
    <Footer v-if="isPc" />
  </div>
</template>

<script setup>
/* 组件 */
import navBar from './component/navBar';
import hot from './component/hot';
import NavBar from '@/component/navBar';
import Footer from '@/component/footer';
import { ErrorCircleFilledIcon } from 'tdesign-icons-vue-next';

/* 工具 */
import { ref ,watch } from "vue"
import { useRoute } from "vue-router";
const route = useRoute();

import { vaildatePc } from '@/util/validate';

/* api */
import { getNewsDetail, recordAccess } from '@/api/home'

// 变量
const detail = ref(null)
const keys = ref([])
const isPc = vaildatePc()
const errorMsg = ref('')

watch(
  () => route.query,
  (value) => {
    if (value && value.id) {
      getDetail()
      
    }
  }
)

// 获取详情
const getDetail = () => {
  const data = {
    id: route.query.id
  }
  if (route.query.flag) {
    data.flag =  Number(route.query.flag)
  } else {
    data.flag = 0
  }
  getNewsDetail(data).then(res => {
    const { code, data, msg } = res.data
    if (code === 0) {
   
      if (msg) {
        errorMsg.value = msg
      }
      if (data) {
        detail.value = data
        if (detail.value.articleType) {
          // 链接
          recordNum(detail.value.articleId)
        } else {
          recordNum(detail.value.id)
        }
        keys.value = detail.value.keywords && detail.value.keywords.split(',')
      } 
    }
  })
}
getDetail()

// 阅读
const recordNum = (id) => {
  recordAccess(id).then(res => {})
}

document.querySelector('meta[name="viewport"]').setAttribute('content', `width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0`)
</script>
<style lang="scss" scoped>
.detail-box{
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
}

.detail {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100% - 240px);
  padding-bottom: 24px;
  .main{
    display: flex;
    justify-content: space-between;
    width: 100%;

    
    .main-left{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Sans Medium;
      padding: 24px;
      box-sizing: border-box;
      .title{
        line-height: 38px;
        color: #1A1A1A;
        font-weight: bold;
        font-size: 28px;
       
        display: flex;
        align-items: center;
        .lable{
          padding: 2px 4px;
          box-sizing: border-box;
          background: #FF3737;
          border-radius: 4px;
          line-height: 24px;
          color: #FFFFFF;
          font-size: 16px;
          margin-right: 12px;
          display: inline-block;
        }
        .detail-title{
          flex: 1;
          word-break: break-all;
        }
      }

      .pub-info{
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        color: #9E9E9E;
        margin:16px 0 24px;
        font-family: Sans Regular;
        .time{
          margin-left: 24px;
        }
      }
      .content{
        width: 100%;
        font-size: 18px;
        line-height: 1.6;
        color: #1A1A1A;
        font-family: Sans Medium;
        word-break: break-all;
        letter-spacing: 1px;
        // text-indent: 2em;
        :deep table {
          border: 1px solid gray !important;
          tbody,tr,td{
            border: 1px solid gray !important;

          }
        }
       
        :deep img {
          width: 100%;
          max-width: 100%;
        }

        // :deep video {
        //   position: relative !important;
        //   left: -2em !important;
        // }
      }
      
      .key{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;
        .keyword{
          height: 24px;
          border-radius: 4px;
          background: #E9E9E9;
          padding: 0 4px;
          box-sizing: border-box;
          font-size: 12px;
          font-weight: bold;
          line-height: 24px;
          color: #999999;
          margin-right: 12px;
          font-family: Sans Regular;
        }
      }
    }

    .pcwidth{
      width: 876px;
      margin-right: 32px;
      padding: 0px;
      .title{
        margin-top: 24px;
      }
      .content{
        font-size: 18px;
      }
    }
    .error-box{
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-size: 20px;

      .error-text{
        margin-top: 24px;
      }
    }
  }
  .mar-t{
    margin-top: 48px;
  }
}
.detailwidth{
  width: 1200px;
}
</style>
